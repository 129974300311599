.principal{
    /* height: 100vh;
    width: 210vh; */
    background-color: #615E99;
}
.stitulo{
    font-size: 30px !important;
    color: #FFF !important;
}
.subtitulo{
    font-size: 18px !important;
    color: #FFF !important;
}
.cancionbase{
    margin-top: 20px;
    -webkit-box-shadow: 0px 1px 8px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 8px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 8px rgba(182,184,214,1);  
}
.botonescancion{
    margin-bottom: 20px;
}
.boton {
    margin-top: 15px;
    color: #5175e9;
    background-color: #fff;
    outline: none;
    border: solid 2px rgb(139, 234, 247);
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-right: 20px; 
}
.botonOff {
    margin-top: 15px;
    color: #fff;
    background-color: #5175e9;
    outline: none;
    border: solid 2px rgb(139, 234, 247);
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-right: 20px; 
}

.boton:hover {
    color:  #fff;
    background-color: #5175e9
}
.grabador {
    color: #fff;
    background-color: #7ac5cd;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 18px;
    text-align: center;
    border-collapse: collapse;
    margin: 0 0 1em 0;
    caption-side: top;
  }
  .sound-wave{
      margin-top: 1px;
      width: 250px;
      height: 15px;
  }
  .recButtonOn {
    background-color: #0deb44;
    background-image: url('../../imagenes/microfono.png');
    background-size: 40px;
    background-repeat: no-repeat; 
    width: 50px;
    height: 50px;
    background-position:center;
    border-radius: 40px;
    border: none;
}
.recButtonOff{
    background-image: url('../../imagenes/microfono.png');
    background-size: 40px;
    background-repeat: no-repeat; 
    width: 50px;
    height: 50px;
    background-position:center;
    border-radius: 40px;
    border: none;
   
}
.stopButtonOn{
    background-color: #e12020;
    background-image: url('../../imagenes/stop.png');
    background-size: 100px;
    background-repeat: no-repeat; 
    width: 50px;
    height: 50px;
    background-position:center;
    border-radius: 40px;
    border: none;
    margin-left: 30px;
}
.stopButtonOff{
    background-image: url('../../imagenes/stop.png');
    background-size: 100px;
    background-repeat: no-repeat; 
    width: 50px;
    height: 50px;
    background-position:center;
    border-radius: 40px;
    border: none;
    margin-left: 30px;
}
.eliButtonOn {
    background-color: #48e120;
    background-image: url('../../imagenes/borrar.png');
    background-size: 40px;
    background-repeat: no-repeat; 
    width: 50px;
    height: 50px;
    background-position:center;
    border-radius: 40px;
    border: none;
    margin-left: 30px;
}
.eliButtonOff{
    background-image: url('../../imagenes/borrar.png');
    background-size: 40px;
    background-repeat: no-repeat; 
    width: 50px;
    height: 50px;
    background-position:center;
    border-radius: 40px;
    border: none;
    margin-left: 30px;  
}
.segundos{
    visibility: hidden;
    background-position: center;
    color: #48e120 !important;
    font-size: 10px !important;
    padding: 0px;
    margin: 0px;

}

.out0, .out1, .out2, .out3{
    visibility: hidden;
    display: none;
}

.output1{
    width: 250px;
    margin-top: 5px;
}
.email {
    width: 100%;
    outline: none;
    margin: 0;
    font-size: 14px;
    height: 25px;
} 
.opcionp {
    color: #fff;
    font-weight: 300;
  }
  
.cont-opcion {
    color: #fff;
    font-size: 18px;
    text-align: left;
    
}
.cont-spinner{
    border: 15px;
    position:fixed;
    top: 35%;
    left: 20%;
    right: 0;
    margin: 0 auto;
    width: 25%;
    height: 385px;
    justify-content: center;
    z-index: 150;

}
.spinner{ 
    display: block;
    margin: 0 auto;
    border-color: red;
}
.cont-evogra{
    border: 15px;
    position:fixed;
    top: 25%;
    left: 20%;
    right: 0;
    margin: 0 auto;
    width: 34%;
    height: 385px;
    justify-content: center;
    font-size: 14px;
    background-color:  #028d7b;
-webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
-moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
margin-bottom: 15px;
    z-index:80;
    
}
.evogra{
    text-align: center;
    background-color: #028d7b;
    height: 100%px;
    width: 100%;
}
.titulo-listevo{
    font-size: 25px;
    color: #48e120;
    text-align: center;
}
.titulo-listcerrar{
    font-size: 30px;
    color: red;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font: bold;
    cursor: pointer;
    float: right;
}
.listevogra {
    border: 15px;
    position:fixed;
    top: 35%;
    left: 20%;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 320px;
    justify-content: center;
    font-size: 16px;
    width: auto;
    overflow: auto;
    background-color:  #028d7b;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;
    z-index:100;
}
.evotabla2gra {
    color: #fff;
    background-color:  #028d7b;
    width: 100%;
    border: 1px solid  #028d7b;
    text-align: left;
    border-collapse: collapse;
    margin: 0 0 1em 0;
    caption-side: top;
  }
  .evocuerpo1{
    cursor: pointer;
}
.odd{
    background-color: #11a794;
}
.cerrarca{
    background-color: #fc1515;
    background-image: url('../../imagenes/borrar.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block ;
    cursor: pointer;
    margin-top: 2px;
  }

