.contenedor1{
    width:100%;
    height: 100%;
    background-color:#001c86;
    position: absolute;
    left: 0;
    top: 0;
    /*background-image: url("img/p1_bkg.png");
    background-position: center center;
    background-size: cover;*/
    overflow: hidden;
}

.p1img1{
    width:450px;
    position: absolute;
    right:-100px;
    top:-100px;
}

.p1img1 img{
    width:100%;
}

.p1img2{
    width:450px;
    position: absolute;
    left:-100px;
    bottom:-100px;
}

.p1img2 img{
    width:100%;
}

.p1inicio{
    width:500px;
    height:500px;
    background-image: url("../../img/p1_img3.png");
    position: absolute;
    left:50%;
    top:50%;
    margin-left: -250px;
    margin-top: -250px;
    text-align: center;
    padding:140px 50px 50px 50px;
}  

#sig1, #mic1, #delete1, #stop1, #mic2, #delete2, #stop2, #mic3, #delete3, #stop3{
    cursor: pointer !important;    
}

.p1inicio .btn{
    width:200px;
    position: relative;
}

.contenedor2{
    width:100%;
    height: 100%;
    background-color:#001c86;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("../../img/p2_bkg.png");
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}
.repro{
    width:450px;
    position: relative;
    cursor: pointer !important; 

    /* left:-100px;
    top:-100px; */
}
.p2img1{
    width:450px;
    position: absolute;
    left:-100px;
    top:-100px;
}

.p2img1 img{
    width:100%;
}

.p2img2{
    width:450px;
    position: absolute;
    right:-100px;
    bottom:-100px;
}

.p2img2 img{
    width:100%;
}

.p2info{
    width:600px;
    height: 400px;
    background-color: #efa5a5;
    border-radius:50px;
    position: absolute;
    left: 50%;
    top:50%;
    margin-left:-300px;
    margin-top:-200px;
    text-align: center;
    padding: 30px;
}

.p2info .titulo{
    width:250px;
}

.p2info .sig{
    width:200px;
    cursor: pointer;
    margin-top:20px;
}

.p2info .linea{
    width: 100%;
    position: relative;
}

.p2info .linea .btn{
    width:20%;
    position: relative;
    float: left;
}

.p2info .linea .btn img{
    width: 100%;
}

.contenedor3{
    width:100%;
    height: 100%;
    background-color:#001c86;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("../../img/p3_bkg.png");
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}
.p3img1{
    width:450px;
    position: absolute;
    right:-100px;
    top:-100px;
}

.p3img1 img{
    width:100%;
}

.p3img2{
    width:450px;
    position: absolute;
    left:-100px;
    bottom:-100px;
}

.p3img2 img{
    width:100%;
}

.p3info{
    width:600px;
    height: 400px;
    background-color: #aab9e0;
    border-radius:50px;
    position: absolute;
    left: 50%;
    top:50%;
    margin-left:-300px;
    margin-top:-200px;
    text-align: center;
    padding: 30px;
}

.p3info .titulo{
    width:250px;
}

.p3info .sig{
    width:200px;
    cursor: pointer;
    margin-top:10px;
}

.p3info .linea{
    width: 60%;
    margin:0 auto;
    position: relative;
}

.p3info .linea .btn{
    width:33%;
    position: relative;
    float: left;
    margin-bottom: 5px;
}

.p3info .linea .btn img{
    width: 100%;
}

.contenedor4{
    width:100%;
    height: 100%;
    background-color:#001c86;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("../../img/p4_bkg.png");
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}
.p4img1{
    width:450px;
    position: absolute;
    right:-100px;
    top:-100px;
}

.p4img1 img{
    width:100%;
}

.p4img2{
    width:450px;
    position: absolute;
    left:-100px;
    bottom:-100px;
}

.p4img2 img{
    width:100%;
}

.p4info{
    width:600px;
    height: 400px;
    background-color: #017ba7;
    border-radius:50px;
    position: absolute;
    left: 50%;
    top:50%;
    margin-left:-300px;
    margin-top:-200px;
    text-align: center;
    padding: 30px;
}

.p4info .titulo{
    width:250px;
}

.p4info .sig{
    width:200px;
    cursor: pointer;
    margin-top:10px;
}

.p4info .linea{
    width: 60%;
    margin:0 auto;
    position: relative;
}

.p4info .linea .btn{
    width:33%;
    position: relative;
    float: left;
    margin-bottom: 5px;
}

.p4info .linea .btn img{
    width: 100%;
}

.contenedor5{
    width:100%;
    height: 100%;
    background-color:#001c86;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("../../img/p5_bkg.png");
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}
.p5img1{
    width:450px;
    position: absolute;
    left:-100px;
    top:-100px;
}

.p5img1 img{
    width:100%;
}

.p5img2{
    width:450px;
    position: absolute;
    right:-100px;
    bottom:-100px;
}

.p5img2 img{
    width:100%;
}

.p5info{
    width:600px;
    height: 400px;
    background-color: #efa5a5;
    border-radius:50px;
    position: absolute;
    left: 50%;
    top:50%;
    margin-left:-300px;
    margin-top:-200px;
    text-align: center;
    padding: 30px;
}

.p5info .titulo{
    width:250px;
}

.p5info .sig{
    width:200px;
    cursor: pointer;
    margin-top:10px;
}

.p5info .linea{
    width: 60%;
    margin:0 auto;
    position: relative;
}

.p5info .linea .btn{
    width:33%;
    position: relative;
    float: left;
    margin-bottom: 5px;
}

.p5info .linea .btn img{
    width: 100%;
}

.contenedor6{
    width:100%;
    height: 100%;
    background-color:#001c86;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("../../img/p6_bkg.png");
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}


.p6info{
    width:600px;
    height: 400px;
    background-color: #aab9e0;
    border-radius:50px;
    position: absolute;
    left: 50%;
    top:50%;
    margin-left:-300px;
    margin-top:-200px;
    text-align: center;
    padding: 30px;
}

.p6info .titulo{
    width:400px;
}

.p6info .sig{
    width:200px;
    cursor: pointer;
    margin-top:20px;
}

.p6info .linea{
    width: 60%;
    margin:0 auto;
    position: relative;
}

.p6info .linea .btn{
    width:33%;
    position: relative;
    float: left;
    margin-bottom: 15px;
}

.p6info .linea .btn img{
    width: 100%;
}

.output{
    width: 300px;
}